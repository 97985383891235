<template>
	<div class="evaluationRecDetail">
		<!-- <el-breadcrumb separator-class="el-icon-arrow-right" style='margin-bottom:20px'>
      <el-breadcrumb-item >资源库管理</el-breadcrumb-item>
      <el-breadcrumb-item to='/zykgl/public'>公告管理</el-breadcrumb-item>
      <el-breadcrumb-item>{{(edit?'编辑':'新增')+'公告'}}</el-breadcrumb-item>
    </el-breadcrumb>-->
		<div class="top">
			<div class="left">
				<ul>
					<li>
						<div class="label">测评记录ID</div>
						<span>{{form.taskNum}}</span>
					</li>
					<li>
						<div class="label">测评人姓名</div>
						<span>{{form.patientName}}</span>
					</li>
					<li>
						<div class="label">终端设备</div>
						<span v-if="dataForm.deviceTypeName">{{dataForm.deviceTypeName.typeName}}{{dataForm.deviceTypeName.typeNumber}}</span>
					</li>
					<li>
						<div class="label">测评得分</div>
						<span>{{dataForm.totalScore}}</span>
					</li>
					<li>
						<div class="label">结果生成时间</div>
						<span>{{form.endTime}}</span>
					</li>
					<li>
						<div class="label">结果发布状态</div>
						<span v-if="form.publishStatus&&form.publishStatus==2">未发布</span>
						<span v-else-if="form.publishStatus&&form.publishStatus==1">已发布</span>
						<span v-else>--</span>

					</li>

				</ul>
			</div>
			<div class="right">
				<ul>
					<li>
						<div class="label">测评时间</div>
						<span>{{form.startTime}}</span>
					</li>
					<li>
						<div class="label">测评人身份证号</div>
						<span>{{form.patientCard}}</span>
					</li>
					<li>
						<div class="label">完成状态</div>

						<span v-if="form.startTime&&form.endTime&&form.isSameDay">监测完成</span>
						<span v-else-if="form.startTime&&!form.endTime&&form.today">进行中</span>
						<span v-else>监测终止</span>

					</li>
					<li>
						<div class="label">评判人</div>
						<span>{{form.judgeUserName}}</span>
					</li>
					<li>
						<div class="label">套餐名称</div>
						<span>{{form.packageName}}</span>
					</li>

				</ul>
			</div>
		</div>
		<div class="top2">
			<div class="left">
				<ul>
					<li>
						<div class="label">结果分析</div>


						<div class="content">{{dataForm.scoreAnalysis}}</div>
					</li>
					<li>
						<div class="label">调整建议</div>
						<div class="content">{{dataForm.scoreAdvice}}</div>

					</li>


				</ul>
			</div>

		</div>
		<div class="label2">数据记录</div>
		<el-divider></el-divider>
		<div v-if="!videoRecord&&!radioRecord&&!(form.vfRelationInfo&&form.vfRelationInfo.length>0)"
			style="display: flex;flex-direction: column;justify-content: center;align-items: center;margin: ;">
			<img style="width: 101px;height: 78px;margin-bottom: 10px;" src="~@/assets/img/zwsj2.png" alt="" />
			<div style="height: 22px;
		font-size: 14px;
		font-family: PingFangSC, PingFang SC;
		font-weight: 400;
		color: #666666;
		line-height: 22px;">暂无数据</div>
		</div>
		<el-tabs tab-position="left" style="margin-left: 65px;">
			<el-tab-pane v-if="videoRecord" label="观看视频">
				<div style="width: 1236px;height: 691px;background: #F7F7F7;padding: 32px 90px;">
					<video style="width:1056px;height:627px" :src="videoRecord.videoUrl" controls
						width="1056px" height="627px"></video>
				</div>

			</el-tab-pane>
			<el-tab-pane v-if="radioRecord" label="朗读文本">
				<div style="width: 1236px;height: 691px;background: #F7F7F7;padding: 32px 90px;">
					<video style="width:1056px;height:627px" :src="radioRecord.videoUrl" controls
						width="1056px" height="627px"></video>
				</div>

				<!-- <div class="ldwb" >
			{{txtContent}}
		</div>
		<audio controls ref="audio" class="aud">
		   <source :src="myDominUrl+radioRecord.videoUrl" />
		 </audio> -->

			</el-tab-pane>
			<el-tab-pane v-if="form.vfRelationInfo&&form.vfRelationInfo.length>0" label="问卷量表">
				<div style="width: 1236px;height:823px;background: #F7F7F7;padding: 32px 24px 48px 24px;">
					<el-tabs v-model="activeQsName" type="card" @tab-click="handleClick">
						<el-tab-pane v-for="(item,index) in form.vfRelationInfo" :label="item.tableName"></el-tab-pane>

					</el-tabs>
					<div style="width: 1188px;height: 675px;overflow: auto;background-color: #fff;">
						<div class="form-title">{{ tableName }}</div>

						<v-form-render style="margin-left: 30px;" v-if="formJson.widgetList.length" :form-json="formJson" :form-data="formData"
							:option-data="optionData" ref="vFormRef"></v-form-render>
					</div>
				</div>


			</el-tab-pane>

		</el-tabs>
		<span slot="footer" v-if="form.publishStatus&&form.publishStatus==1" style="margin-top: 20px;"
			class="dialog-footer">
			<el-button type="primary" @click="dialogVisible = true">评分</el-button>
		</span>
		<el-dialog title="评分" :close-on-click-modal="false" :visible.sync="dialogVisible" width="1200px">
			<div class="scoreContent"><span style="margin-right: 24px;"
					v-for="item in formList">{{item.score1}}~{{item.score2}}分：{{item.cpjg}}</span> </div>
			<el-form ref="dataForm" label-width="140px" class="demo-dataForm">
				<div class="top">
					<div class="left">

						<el-form-item label="评分" required>
							<el-input @blur="resetScore()" style="width: 256px;" v-model="dataForm.totalScore"
								placeholder="请输入"></el-input>
						</el-form-item>

					</div>
					<div class="right">

						<el-form-item label="测评结果" required>
							<el-input style="width: 256px;" v-model="dataForm.totalResult" placeholder="请输入"></el-input>
						</el-form-item>
					</div>

				</div>
				<el-form-item label="结果分析" required>
					<el-input type="textarea" :rows="5" v-model="dataForm.scoreAnalysis" placeholder="请输入"></el-input>
				</el-form-item>
				<el-form-item label="调整建议" required>
					<el-input type="textarea" :rows="5" v-model="dataForm.scoreAdvice" placeholder="请输入"></el-input>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button type="primary" @click="dataFormSubmit(1)">保存并发布</el-button>
				<el-button @click="dataFormSubmit(2)">暂存</el-button>
			</span>
		</el-dialog>

	</div>
</template>
<script>
	import TinyMce from "@/components/tiny-mce";
	import Global from '@/utils/global'
	import Qs from "qs";
	import moment from "moment";
	export default {
		name: "publicAdd",
		components: {
			TinyMce,
		},
		data() {
			return {
				activeQsName: '',
				tableName: '',
				formJson: {
					widgetList: [],
					formConfig: {},
				},
				formList: [],
				dataForm: {
					totalScore: '',
					scoreAnalysis: '',
					scoreAdvice: '',
				},

				formData: {},
				optionData: {},
				edit: false,
				dialogVisible: false,
				actionUrl: '',
				importHeader: {
					token: ""
				},
				myDominUrl: '',
				fileList: [],
				fileList2: [],
				checkList: [],
				txtContent: '',
				form: {

				},
				videoRecord: null,
				radioRecord: null,
				diseasesList: [],
				typeList: [],
				dataRules: {
					modelName: [{
						required: true,
						message: "AI测评模型名称不能为空",
						trigger: "blur"
					}, ],
					modelNumber: [{
						required: true,
						message: "模型编号不能为空",
						trigger: "blur"
					}, ],
					diseaseType: [{
						required: true,
						message: "请选择分类",
						trigger: "change"
					}, ],

				},
				dataListLoading: false,
				type: "",
			};
		},
		async mounted() {
			this.myDominUrl = Global.dominUrl
			console.log(this.myDominUrl)
			if (this.$route.query.id) {

				await this.init(this.$route.query.id);
			}


		},
		methods: {
			resetScore(val) {

				if(parseInt(this.dataForm.totalScore)<parseInt(this.formList[0].score1)||parseInt(this.dataForm.totalScore)>parseInt(this.formList[this.formList.length-1].score2)){
					this.$message.error('请正确填写评分')
					this.dataForm.totalScore =''
					return
				}
				for (let item of this.formList) {
					if (parseInt(this.dataForm.totalScore) >= parseInt(item.score1) && parseInt(this.dataForm
						.totalScore) <= parseInt(item.score2)) {

						this.dataForm.totalResult = item.cpjg
						this.dataForm.scoreAnalysis = item.jgfx
						this.dataForm.scoreAdvice = item.tzjy
					}
				}
			},
			handleClick(val) {
				console.log(val)
				this.getInfo(this.form.vfRelationInfo[val.index])
			},
			async getInfo(tableInfo) {
				var that = this;
				const {
					data
				} = await this.$httpAes({
					url: that.$httpAes.adornUrl("/vfTableInfo/info"),
					method: "post",
					data: {
						stringParam1: tableInfo.infoId,
					},
					contentType: 'json',
					headers: {
						'Content-Type': 'application/json',

					},
				});
				if (data.status) {
					console.log('tableInfo')
					console.log(data.data)
					this.tableName = data.data.tableName;
					if (data.data && data.data.tableContent) {
						this.formJson = JSON.parse(data.data.tableContent);
					

						console.log(this.formJson)
						this.$nextTick(() => {
								that.$refs["vFormRef"].setFormJson(this.formJson);
							that.$refs["vFormRef"].disableForm(true);
							setTimeout(()=>{
								that.$refs["vFormRef"].disableForm(true);
							},500)
							this.getWriteInfo(tableInfo);
						});
						// if (this.$route.query.activeName == 3) {
						//   this.getWriteInfo(tableInfo);
						// }


					}
				} else {
					that.$message({
						message: data.data.msg,
						type: "error",
						duration: 1500,
						onClose: () => {},
					});
				}
			},
			async dataFormSubmit(status) {
				if (this.dataForm.totalScore == '' || this.dataForm.totalResult == '' || this.dataForm.scoreAnalysis ==
					'' || this.dataForm.scoreAdvice == '') {
					this.$message.error('请填写完所有评分项')
					return
				}

				let obj = {
					id: this.taskId,
					totalScore: this.dataForm.totalScore,
					totalResult: this.dataForm.totalResult,
					scoreAnalysis: this.dataForm.scoreAnalysis,
					scoreAdvice: this.dataForm.scoreAdvice,
					publishStatus: status,
					judgeUserId: JSON.parse(sessionStorage.userInfo).id
				}
				if (status == 1) {
					obj.publishTime = moment(new Date()).format(
						"YYYY-MM-DD HH:mm:ss"
					)
					obj.resultTime = moment(new Date()).format(
						"YYYY-MM-DD HH:mm:ss"
					)
				}
				const {
					data: res
				} = await this.$httpAes({
					url: this.$httpAes.adornUrl(
						"/diagnosisTask/update"
					),
					method: "post",
					contentType: 'json',
					headers: {
						'Content-Type': 'application/json',

					},
					data: obj,
				});
				console.log(res);
				if (res.status) {
					this.$message({
						message: res.msg,
						type: "success",
						duration: 1500,
						onClose: () => {
							this.dialogVisible = false

						},
					});


				}
			},
			async getWriteInfo(tableInfo) {
				var that = this;
				console.log('getWriteInfo')
				// let formInfo = {};
				// if (localStorage.currentPatientFormInfo) {
				//   formInfo = JSON.parse(localStorage.currentPatientFormInfo);
				//   console.log(formInfo, "formInfo");
				// }
				const {
					data
				} = await this.$httpAes({
					url: that.$httpAes.adornUrl("/vfWriteInfo/info"),
					method: "post",
					data: {
						stringParam1: tableInfo.infoId,
						stringParam2: tableInfo.id,
						stringParam3: tableInfo.taskId,
						stringParam4: tableInfo.patientId,
					},
					contentType: 'json',
					headers: {
						'Content-Type': 'application/json',

					},
				});
				if (data.status) {
					let list = [],
						newArr = [];
					if (data.data) {
						list = JSON.parse(data.data);
						// console.log('list',list)
						if (list.length) {
							list.forEach((ele) => {
								if (ele.cellId.indexOf('radio') != -1) {
									ele.fillContent = parseInt(ele.fillContent);
								}
								if (ele.cellId.indexOf('checkbox') != -1) {
									if (ele.fillContent.indexOf('[') > -1) {
										ele.fillContent = JSON.parse(ele.fillContent);
									}
								}

								if (ele.fillType == "checkbox") {
									if (ele.fillContent.indexOf('[') > -1) {
										ele.fillContent = JSON.parse(ele.fillContent);
									}
									// if (typeof ele.fillContent == 'number') {
									//   ele.fillContent = [ele.fillContent]
									// }
								}
								if (
									ele.fillType == "number" ||
									ele.fillType == "radio" ||
									ele.fillType == "slider"
								) {
									console.log(typeof ele.fillContent);
									ele.fillContent = parseInt(ele.fillContent);
									// if (typeof ele.fillContent == "string") {

									// }
								}
								if (
									ele.fillType == "picture-upload" ||
									ele.fillType == "file-upload" ||
									ele.fillType == "pictureupload"
								) {
									if (typeof ele.fillContent == "string") {
										if (ele.fillContent.indexOf("[") > -1) {
											ele.fillContent = JSON.parse(ele.fillContent);
										} else {
											ele.fillContent = [{
												url: ele.fillContent,
												name: "图片",
											}, ];
										}
									}
								}
								if (ele.fillType == "select") {
									if (ele.fillContent.indexOf("[") > -1) {
										ele.fillContent = JSON.parse(ele.fillContent);
									} else {
										ele.fillContent = parseInt(ele.fillContent);
									}
								}
								let newObj = {
									[ele.cellId]: ele.fillContent,
								};
								newArr.push(newObj);
							});
						}
						console.log("list", list);
						let formData = {};
						for (let key in [...newArr]) {
							formData = Object.assign(this.formData, [...newArr][key]);
						}
						console.log("=================================formData")
						console.log(formData)
						this.$nextTick(() => {
							this.$refs["vFormRef"].setFormData(formData);
						});
					}
				} else {
					that.$message({
						message: data.msg,
						type: "error",
						duration: 1500,
						onClose: () => {},
					});
				}
			},
			setCheck(val) {



			},
			handleSuccess2(response, file, fileList) {
				let obj = {
					name: file.name,
					url: response.result.filePath
				}
				this.fileList2 = [obj]
			},
			handleSuccess(response, file, fileList) {
				console.log(response, "response");
				let obj = {
					name: file.name,
					url: response.result.filePath
				}
				this.fileList = [obj]

			},
			beforeUpload(file, fileType) {
				let fileName = file.name;
				let pos = fileName.lastIndexOf(".");
				let lastName = fileName.substring(pos, fileName.length);
				let type = lastName.toLowerCase();
				if (fileType == "1") {

					if (
						type != ".mp4"

					) {
						this.$message.error(
							"上传文件只能是.mp4 格式!"
						);
						this.fileList = [];
						return false;
					}
					return true;
				} else if (fileType == "2") {
					// let isLt2M = file.size / 1024 / 1024 < 50;
					if (type != ".txt") {
						this.$message.error("上传文件只能是.txt格式!");
						this.fileList2 = [];
						return false;
					}

					return true;
				}
			},
			back() {
				this.$router.back();
			},
			async showScore() {
				console.log('showScore')
				let itemObj = JSON.parse(sessionStorage.evaluationRecDetail)
				console.log()
				this.dataForm.totalScore = itemObj.totalScore
				this.taskId = itemObj.id
				const {
					data: res
				} = await this.$httpAes({
					url: this.$httpAes.adornUrl("/evaluationPackage/info"),
					method: "post",
					data: {
						stringParam1: itemObj.packageId
					},
					contentType: 'json',
					headers: {
						'Content-Type': 'application/json',

					},
				});
				if (res.status) {
					// this.form = res.data;

					if (res.data.packageRule && res.data.packageRule.length > 0) {
						this.formList = JSON.parse(res.data.packageRule)
						console.log(this.formList)
						if (itemObj.totalResult && itemObj.totalResult.length > 0) {
							this.dataForm.totalResult = itemObj.totalResult
							this.dataForm.scoreAnalysis = itemObj.scoreAnalysis
							this.dataForm.scoreAdvice = itemObj.scoreAdvice
						} else {
							for (let item of this.formList) {
								if (this.dataForm.totalScore >= item.score1 && this.dataForm.totalScore <= item
									.score2) {

									this.dataForm.totalResult = item.cpjg
									this.dataForm.scoreAnalysis = item.jgfx
									this.dataForm.scoreAdvice = item.tzjy
								}
							}
						}

					}




				}

			},
			async init(id) {
				this.showScore()
				if (sessionStorage.evaluationRecDetail != undefined) {


					this.form = JSON.parse(sessionStorage.evaluationRecDetail)
					if (this.form.vfRelationInfo && this.form.vfRelationInfo.length > 0) {

						this.getInfo(this.form.vfRelationInfo[0])

					}

					console.log('this.form')
					console.log(this.form)
					for (let item of this.form.diagnosisRecord) {
						item.diagnosisModel = JSON.parse(item.diagnosisModel)
						if (item.diagnosisModel.stimulusForm == '视频观看') {
							this.videoRecord = item
						}
						if (item.diagnosisModel.stimulusForm == '文字朗读') {
							this.radioRecord = item
							//this.listPic(item.modelId)
						}
					}
				}

			},

			async getTypeList() {
				let params = {
					stringParam1: "病种",
				};
				// if(localStorage.getItem('currentTitle')=='康复时空健康管理'&&localStorage.getItem('login'))
				const {
					data: res
				} = await this.$httpAes({
					url: this.$httpAes.adornUrl("/dict/list"),
					method: "post",
					data: params,
					contentType: 'json',
					headers: {
						'Content-Type': 'application/json',

					},
				});
				// console.log(res,'res')
				this.typeList = res.data;
				let obj = this.typeList.find(this.findType)
				console.log('findfindfindfindfindfind')
				console.log(obj)
				this.form.diseaseTypeName = obj.name
			},
			findType(item) {
				return item.value == this.form.diseaseType
			},
			async listPic(id) {
				var that = this
				this.txtContent = ''
				let params = {
					stringParam1: id,
					intParam1: 119
				};

				const {
					data: res
				} = await this.$httpAes({
					url: this.$httpAes.adornUrl("/sysPic/listPic"),
					method: "post",
					data: params,
					contentType: 'json',
					headers: {
						'Content-Type': 'application/json',

					},
				});

				let fileList2 = JSON.parse(res.data[0].picUrl)
				let request = new XMLHttpRequest();
				request.open("GET", fileList2[0].url, true);
				request.onload = function() {
					if (this.status >= 200 && this.status < 400) {
						let data = this.responseText;

						that.txtContent = data
					} else {
						console.error("获取文件内容失败");
					}
				};
				request.onerror = function() {
					console.error("网络连接异常");
				};
				request.send();
			},
			async insertPic(actionId) {

				let params = {
					stringParam1: []

				}

				if (this.checkList.includes('视频观看')) {
					let obj = {
						actionId: actionId,
						picUrl: JSON.stringify(this.fileList),
						picType: 118,
					}
					params.stringParam1.push(obj)
				}
				if (this.checkList.includes('文字朗读')) {
					let obj = {
						actionId: actionId,
						picUrl: JSON.stringify(this.fileList2),
						picType: 119,
					}
					params.stringParam1.push(obj)
				}
				// if(localStorage.getItem('currentTitle')=='康复时空健康管理'&&localStorage.getItem('login'))
				const {
					data: res
				} = await this.$httpAes({
					url: this.$httpAes.adornUrl("/sysPic/insertMultiHousePic"),
					method: "post",
					data: params,
					contentType: 'json',
					headers: {
						'Content-Type': 'application/json',

					},
				});
				console.log(res, 'res')
				if (res.status) {
					this.$router.go(-1)
				}
			},
			async deleteHousePic(id) {
				let params = {
					stringParam1: id,
				};
				// if(localStorage.getItem('currentTitle')=='康复时空健康管理'&&localStorage.getItem('login'))
				const {
					data: res
				} = await this.$httpAes({
					url: this.$httpAes.adornUrl("/sysPic/deleteHousePic"),
					method: "post",
					data: params,
					contentType: 'json',
					headers: {
						'Content-Type': 'application/json',

					},
				});

			},

			async submit() {
				if (this.checkList.includes('视频观看') && this.fileList.length == 0) {
					this.$message.error('请上传视频文件')
					return
				}
				if (this.checkList.includes('文字朗读') && this.fileList2.length == 0) {
					this.$message.error('请上传文本文件')
					return
				}
				this.$refs.form.validate(async (valid) => {
					if (valid) {
						let form = this.form;
						form.stimulusForm = this.checkList.join(',')

						const {
							data: res
						} = await this.$httpAes({
							url: this.$httpAes.adornUrl(
								form.id ? "/diagnosisModel/update" : "/diagnosisModel/add"
							),
							method: "post",
							contentType: 'json',
							headers: {
								'Content-Type': 'application/json',

							},
							data: this.form,
						});
						console.log(res);
						if (res.status) {
							this.$message.success(res.msg);
							if (form.id) {
								await this.deleteHousePic(res.data.id)
								await this.insertPic(res.data.id)
							} else {
								this.insertPic(res.data.id)
							}

						}
					} else {
						return false;
					}
				});
			},
		},
	};
</script>

<style lang="scss">
	// .el-tabs__active-bar{
	// 	background-color: #fff !important;
	// }
	// .el-radio__input.is-checked+.el-radio__label {
	//     color: #a767fd !important;
	// }
	.evaluationRecDetail {
		.aud {
			width: 278px;
			height: 51px;
			margin-top: 30px;
			margin-left: calc(50% - 139px);
		}

		.form-title {
			font-size: 20px;
			color: #A767FD;
			font-weight: bold;
			width: 100%;
			text-align: center;
			padding: 10px 0 40px;
		}

		.label2 {
			height: 26px;
			font-size: 16px;
			font-family: PingFangSC, PingFang SC;
			font-weight: 500;
			color: #333333;
			line-height: 26px;
		}

		.scoreContent {
			font-size: 14px;
			font-family: PingFangSC, PingFang SC;
			font-weight: 400;
			color: #333333;
			line-height: 22px;
			width: 100%;
			height: 54px;
			background: #F8F3FF;
			box-sizing: border-box;
			padding: 16px;
			margin-bottom: 24px;
		}

		.ldwb {
			white-space: pre-wrap;
			font-size: 14px;
			font-family: PingFangSC, PingFang SC;
			font-weight: 400;
			color: #333333;
			line-height: 22px;
			text-indent: 2ch;
		}

		.top2 {
			display: flex;

			// height: 200px;
			.left {
				height: 100%;
				width: 100%;

				//   background-color: aqua;
				ul {
					li {
						display: flex;
						padding: 12px 0px;

						.label {
							width: 140px;
							height: 22px;
							font-size: 14px;
							font-family: PingFangSC-Regular, PingFang SC;
							font-weight: 400;
							color: #999999;
							line-height: 22px;
							text-align: right;
						}

						.content {
							width: calc(100% - 156px);
							margin-left: 16px;
						}

						span {
							margin-left: 16px;
							height: 22px;
							font-size: 14px;
							font-family: PingFangSC-Regular, PingFang SC;
							font-weight: 400;
							color: #333333;
							line-height: 22px;
						}
					}
				}
			}


		}

		.top {
			display: flex;

			// height: 200px;
			.left {
				height: 100%;
				width: 50%;

				//   background-color: aqua;
				ul {
					li {
						display: flex;
						padding: 12px 0px;

						.label {
							width: 140px;
							height: 22px;
							font-size: 14px;
							font-family: PingFangSC-Regular, PingFang SC;
							font-weight: 400;
							color: #999999;
							line-height: 22px;
							text-align: right;
						}

						span {
							margin-left: 16px;
							height: 22px;
							font-size: 14px;
							font-family: PingFangSC-Regular, PingFang SC;
							font-weight: 400;
							color: #333333;
							line-height: 22px;
						}
					}
				}
			}

			.right {
				height: 100%;
				flex: 1;

				//   background-color: antiquewhite;
				ul {
					li {
						display: flex;
						padding: 12px 0px;

						.label {
							width: 140px;
							height: 22px;
							font-size: 14px;
							font-family: PingFangSC-Regular, PingFang SC;
							font-weight: 400;
							color: #999999;
							line-height: 22px;
							text-align: right;
						}

						span {
							margin-left: 16px;
							height: 22px;
							font-size: 14px;
							font-family: PingFangSC-Regular, PingFang SC;
							font-weight: 400;
							color: #333333;
							line-height: 22px;
						}
					}
				}
			}
		}
	}
</style>